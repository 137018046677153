import React from "react";
import Styles from "./Styles";

const YouTubeEmbed = ({ youtubeId, title }) => {
  return (
    <Styles.YouTubeEmbed>
      <iframe
        width="560"
        height="315"
        src={`https://www.youtube.com/embed/${youtubeId}`}
        title={title}
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </Styles.YouTubeEmbed>
  );
};

export default YouTubeEmbed;
