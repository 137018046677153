import React from "react"
import Styles from "./Styles"
import Section from "../../common/Section"
import YouTubeEmbed from "../../common/YouTubeEmbed"

const VideoSection = ({ id, heading, number }) => {
  return (
    <Styles.VideoSection>
      <Section number={number} heading={heading} id={id}>
        <Styles.Videos>
          <YouTubeEmbed youtubeId="IVCUoT8mRlM" title="The Goudies - Breathe" />
          <YouTubeEmbed
            youtubeId="TEZe6Jmhe0A"
            title="The Goudies - When This Is Over"
          />
          <YouTubeEmbed
            youtubeId="Kc0G85LwJ7Q"
            title="The Goudies - Two (The East Street Deli Session)"
          />
          <YouTubeEmbed youtubeId="gc-NpAC8Tp0" title="The Goudies - Ashes" />
          <YouTubeEmbed
            youtubeId="Fx2avyEW4wU"
            title="The Goudies - Maggie's Prayers"
          />
          <YouTubeEmbed
            youtubeId="cG-ZDl5yPc8"
            title="The Goudies - It's Not Unusual"
          />
        </Styles.Videos>
      </Section>
    </Styles.VideoSection>
  )
}

export default VideoSection
