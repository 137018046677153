import styled from "styled-components"

const SocialLinks = styled.ul`
  width: 100%;
  display: grid;
  list-style: none;
  margin: ${({ theme }) => `${theme.space[5]} 0 0`};
  padding: 0;
  grid-gap: ${({ theme }) => theme.space[1]};
  grid-auto-flow: column;
  justify-content: center;
  grid-auto-columns: ${({ theme }) => theme.space[5]};

  li {
    height: ${({ theme }) => theme.space[4]};
    width: ${({ theme }) => theme.space[4]};

    a,
    img {
      height: 100%;
      width: 100%;
    }
  }

  @media ${({ theme }) => theme.device.mobileL} {
    grid-gap: ${({ theme }) => theme.space[2]};
    li {
      height: ${({ theme }) => theme.space[5]};
      width: ${({ theme }) => theme.space[5]};
    }
  }
`

export default {
  SocialLinks,
}
