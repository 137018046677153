import styled from "styled-components";

const ConnectSection = styled.div`
  text-align: center;
`;

const ConnectMailingList = styled.div`
  margin-top: ${({ theme }) => theme.space[6]};
  margin-bottom: ${({ theme }) => theme.space[7]};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    margin-top: 0;
    max-width: ${({ theme }) => theme.space[12]};

    @media ${({ theme }) => theme.device.laptop} {
      max-width: ${({ theme }) => theme.space[13]};
    }
  }
`;

const ConnectSocial = styled.div`
  margin-bottom: ${({ theme }) => theme.space[4]};
`;

export default {
  ConnectSection,
  ConnectMailingList,
  ConnectSocial,
};
