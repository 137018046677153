import styled from "styled-components"

const ShowsSection = styled.div``

const ShowsSectionShowList = styled.div`
  display: grid;
  grid-gap: ${({ theme }) => theme.space[4]};
`

const ShowsSectionShow = styled.div`
  text-transform: uppercase;
  display: grid;
  grid-gap: ${({ theme }) => theme.space[2]};
  border-bottom: 2px solid ${({ theme }) => theme.color.darkBrown};
  padding-bottom: ${({ theme }) => theme.space[2]};

  h3 {
    font-family: "ApfelGrotezk-Regular", "Helvetica", Arial, sans-serif;
    width: 100%;
  }

  h3,
  p {
    margin: 0;
    padding: 0;
  }

  a {
    text-decoration: none;
  }

  @media ${({ theme }) => theme.device.mobileL} {
    grid-template-columns: ${({ theme }) => `1fr auto`};
    align-items: center;
  }

  @media ${({ theme }) => theme.device.tablet} {
    h3 {
      font-size: ${({ theme }) => theme.fontSize[6]};
    }

    p {
      font-size: ${({ theme }) => theme.fontSize[5]};
    }
  }
`

export default {
  ShowsSection,
  ShowsSectionShow,
  ShowsSectionShowList,
}
