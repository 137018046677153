import React from "react"
import Styles from "./Styles"
import Section from "../../common/Section"
import ButtonLink from "../../common/ButtonLink"
import SocialLinks from "../SocialLinks"

const ConnectSection = ({ id, heading, number }) => {
  return (
    <Styles.ConnectSection>
      <Section number={number} heading={heading} id={id}>
        <Styles.ConnectMailingList>
          <h2>
            Join The Goudies
            <br />
            Mailing List
          </h2>
          <p>
            Sign up to get Goudie Mail, tour updates, live sessions, new
            releases, and more...
          </p>
          <ButtonLink
            label="Sign up now"
            to="https://thegoudies.ck.page/newsletter"
          />
        </Styles.ConnectMailingList>
        <Styles.ConnectSocial>
          <h3>Follow The Goudies</h3>
          <SocialLinks />
        </Styles.ConnectSocial>
      </Section>
    </Styles.ConnectSection>
  )
}

export default ConnectSection
