import styled from "styled-components"

const HomeHeader = styled.div`
  width: 100%;
  height: ${({ theme }) => theme.space[12]};

  @media ${({ theme }) => theme.device.tablet} {
    margin-bottom: ${({ theme }) => theme.space[5]};
  }

  @media ${({ theme }) => theme.device.laptop} {
    height: ${({ theme }) => theme.space[14]};
  }

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: top;
  }
`

export default {
  HomeHeader,
}
