import styled from "styled-components";

const AboutSection = styled.div``;

const AboutContent = styled.div`
  @media ${({ theme }) => theme.device.laptop} {
    display: grid;
    grid-gap: ${({ theme }) => theme.space[5]};
    grid-template-columns: ${({ theme }) => `${theme.space[12]} 1fr`};
  }
`;

const AboutPortrait = styled.img`
  width: 100%;
  height: ${({ theme }) => theme.space[11]};
  object-fit: cover;
  object-position: top;

  @media ${({ theme }) => theme.device.mobileM} {
    height: ${({ theme }) => theme.space[12]};
  }
`;

const AboutText = styled.div`
  p:first-of-type {
    font-size: ${({ theme }) => theme.fontSize[4]};
    line-height: 1.6;

    @media ${({ theme }) => theme.device.tablet} {
      font-size: ${({ theme }) => theme.fontSize[5]};
      line-height: 1.5;
    }
  }

  p {
    margin: ${({ theme }) => `${theme.space[4]} 0 ${theme.space[5]}`};
  }
`;

export default {
  AboutSection,
  AboutContent,
  AboutPortrait,
  AboutText,
};
