import React from "react"
import Styles from "./Styles"
import headerImage from "../../../images/the-goudies-213.jpg"

const HomeHeader = () => {
  return (
    <Styles.HomeHeader>
      <img src={headerImage} alt="Sam and Beth Goudie sat together" />
    </Styles.HomeHeader>
  )
}

export default HomeHeader
