import React from "react"
import Styles from "./Styles"

import facebookImg from "../../../images/social/facebook-brands.svg"
import instagramImg from "../../../images/social/instagram-brands.svg"
import youtubeImg from "../../../images/social/youtube-brands.svg"
import twitterImg from "../../../images/social/twitter-brands.svg"
import spotifyImg from "../../../images/social/spotify-brands.svg"
import appleImg from "../../../images/social/apple-brands.svg"
import mailImg from "../../../images/social/envelope-regular.svg"

const links = [
  {
    label: "Instagram",
    url: "https://www.instagram.com/thegoudies",
    icon: instagramImg,
  },
  {
    label: "Facebook",
    url: "https://www.facebook.com/Sam-Beth-Goudie-101291688855946",
    icon: facebookImg,
  },
  {
    label: "Twitter",
    url: "https://www.twitter.com/thegoudies",
    icon: twitterImg,
  },
  {
    label: "Spotify",
    url: "https://open.spotify.com/artist/3l5tHniYQSg6ogzkOeEpnw?si=O0g1MA7QSiiIB6NdmOx0ZQ&dl_branch=1",
    icon: spotifyImg,
  },
  {
    label: "Apple",
    url: "https://music.apple.com/gb/artist/the-goudies/1576704285",
    icon: appleImg,
  },
  {
    label: "YouTube",
    url: "https://www.youtube.com/channel/UCZSFU3sTqYsWLmSBcklGyDw",
    icon: youtubeImg,
  },
]

const SocialLinks = () => {
  return (
    <Styles.SocialLinks>
      {links.map(({ label, url, icon }) => (
        <li key={label}>
          <a href={url}>
            <img src={icon} alt={label} />
          </a>
        </li>
      ))}
    </Styles.SocialLinks>
  )
}

export default SocialLinks
