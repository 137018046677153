import styled from "styled-components";

const MusicSection = styled.div`
  text-align: center;
  position: relative;

  iframe {
    margin: ${({ theme }) => `${theme.space[6]} auto ${theme.space[4]}`};
    display: block;
  }
`;

const MusicHeader = styled.div`
  margin: ${({ theme }) => `${theme.space[6]} auto`};

  // RESET
  h2,
  h4,
  p {
    margin: 0;
  }

  h2 {
    margin-top: ${({ theme }) => theme.space[1]};
    margin-bottom: ${({ theme }) => theme.space[3]};
  }

  p {
    text-transform: uppercase;
    color: ${({ theme }) => theme.color.lightBrown};
  }
`;

const MusicCoverImage = styled.img`
  margin: ${({ theme }) => `${theme.space[3]} auto ${theme.space[5]}`};
  display: block;
  width: 100%;
  max-width: ${({ theme }) => theme.space[12]};
`;

const MusicSoundcloudTracks = styled.div`
  /* display: grid;
  grid-gap: ${({ theme }) => theme.space[2]}; */
`;

export default {
  MusicSection,
  MusicHeader,
  MusicCoverImage,
  MusicSoundcloudTracks,
};
