import React from "react"
import Styles from "./Styles"
import Section from "../../common/Section"
import ButtonLink from "../../common/ButtonLink"

import wtioCoverImg from "../../../images/wtio-cover-min.jpg"
import twoCoverImg from "../../../images/two-cover-min.jpg"
import laCoverImg from "../../../images/la-cover-min.jpg"

const MusicSection = ({ id, heading, number, context }) => {
  return (
    <Styles.MusicSection>
      <Section number={number} heading={heading} id={id}>
        <Styles.MusicHeader>
          <h4>The Goudies</h4>
          <h2>Breathe</h2>
          <p>Available 01/12/23</p>
        </Styles.MusicHeader>
        <a href="https://thegoudies.ffm.to/breathe">
          <Styles.MusicCoverImage
            src="https://res.cloudinary.com/goudie-works/image/upload/w_700/q_auto/f_auto/v1694072118/MCR/goudies-breathe-cover.jpg"
            width="500"
          />
        </a>
        <ButtonLink label="Buy/Stream" to="https://thegoudies.ffm.to/breathe" />
        <Styles.MusicHeader>
          <h4>The Goudies</h4>
          <h2>L.A. Never Leaves Me</h2>
          <p>Available 11/08/23</p>
        </Styles.MusicHeader>
        <a href="https://thegoudies.ffm.to/laneverleavesme">
          <Styles.MusicCoverImage src={laCoverImg} width="500" />
        </a>
        <ButtonLink
          label="Buy/Stream"
          to="https://thegoudies.ffm.to/laneverleavesme"
        />
        <Styles.MusicHeader>
          <h4>The Goudies</h4>
          <h2>Two</h2>
          <p>The East Street Deli Session - Available 04/11/22</p>
        </Styles.MusicHeader>
        <a href="https://thegoudies.ffm.to/two_theeaststreetdelisession">
          <Styles.MusicCoverImage src={twoCoverImg} width="500" />
        </a>
        <ButtonLink
          label="Buy/Stream"
          to="https://thegoudies.ffm.to/two_theeaststreetdelisession"
        />
        <Styles.MusicHeader>
          <h4>The Goudies</h4>
          <h2>When This Is Over</h2>
          <p>The debut EP - Available now</p>
        </Styles.MusicHeader>
        <a href="https://thegoudies.ffm.to/whenthisisover">
          <Styles.MusicCoverImage src={wtioCoverImg} width="500" />
        </a>
        <ButtonLink
          label="Buy/Stream"
          to="https://thegoudies.ffm.to/whenthisisover"
        />
        {context === "epk" ? (
          <Styles.MusicSoundcloudTracks>
            <iframe
              width="100%"
              height="166"
              scrolling="no"
              frameborder="no"
              allow="autoplay"
              src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1085664247&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
            ></iframe>
            <iframe
              width="100%"
              height="166"
              scrolling="no"
              frameborder="no"
              allow="autoplay"
              src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1085664454&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
            ></iframe>
            <iframe
              width="100%"
              height="166"
              scrolling="no"
              frameborder="no"
              allow="autoplay"
              src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1085664310&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
            ></iframe>
            <iframe
              width="100%"
              height="166"
              scrolling="no"
              frameborder="no"
              allow="autoplay"
              src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1108226761&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
            ></iframe>
          </Styles.MusicSoundcloudTracks>
        ) : (
          <>
            <iframe
              src="https://open.spotify.com/embed/album/7HVVg7oPn9C4v49p6Tbk1F"
              width="300"
              height="80"
              frameborder="0"
              allowtransparency="true"
              allow="encrypted-media"
            ></iframe>
            <iframe
              src="https://open.spotify.com/embed/album/3gaSzOyo7RgruhcjTm1wSp"
              width="300"
              height="80"
              frameborder="0"
              allowtransparency="true"
              allow="encrypted-media"
            ></iframe>
          </>
        )}
      </Section>
    </Styles.MusicSection>
  )
}

export default MusicSection
