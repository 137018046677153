import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Styles from "./Styles"
import Section from "../../common/Section"
import ButtonLink from "../../common/ButtonLink"
import Link from "../../common/Link"

const ShowsSection = ({ id, heading, number }) => {
  return (
    <StaticQuery
      query={graphql`
        query ShowsQuery {
          allPrismicShow(sort: { fields: data___date, order: ASC }) {
            edges {
              node {
                data {
                  title {
                    text
                  }
                  venue {
                    text
                  }
                  location {
                    text
                  }
                  date(formatString: "DD/MM/YYYY")
                  link_label {
                    text
                  }
                  link {
                    url
                  }
                  announced
                }
                uid
                id
              }
            }
          }
        }
      `}
      render={(data) => {
        const Shows = data.allPrismicShow.edges
        return (
          <Styles.ShowsSection>
            <Section number={number} heading={heading} id={id}>
                  <h2><a href="https://www.bandsintown.com/a/15511243-the-goudies">Live Shows</a></h2>
                  <p>See our tour dates and buy tickets <a href="https://www.bandsintown.com/a/15511243-the-goudies">here</a></p>
{/*               <Styles.ShowsSectionShowList>
                {Shows.map(({ node }) =>
                  node.data.announced ? (
                    <Styles.ShowsSectionShow>
                      <div>
                        <h3>{node.data.title.text}</h3>
                        <p>
                          {node.data.date} - {node.data.venue.text} -{" "}
                          {node.data.location.text}
                        </p>
                      </div>
                      {node.data.link.url && (
                        <ButtonLink
                          label={node.data.link_label.text}
                          to={node.data.link.url}
                          target="_blank"
                          size="s"
                        />
                      )}
                    </Styles.ShowsSectionShow>
                  ) : null
                )}
              </Styles.ShowsSectionShowList> */}
            </Section>
          </Styles.ShowsSection>
        )
      }}
    />
  )
}

export default ShowsSection
