import styled from "styled-components";

const VideoSection = styled.div``;

const Videos = styled.div`
  display: grid;
  grid-gap: ${({ theme }) => theme.space[4]};
  width: 100%;
  max-width: ${({ theme }) => theme.space[14]};
  margin: 0 auto;

  @media ${({ theme }) => theme.device.laptop} {
    max-width: ${({ theme }) => theme.space[19]};

    grid-template-columns: ${({ theme }) => `1fr 1fr`};
  }
`;

export default {
  VideoSection,
  Videos,
};
