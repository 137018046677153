import React from "react"
import Styles from "./Styles"
import Section from "../../common/Section"

import portraitImage from "../../../images/the-goudies-213.jpg"

const AboutSection = ({ id, heading, number, context }) => {
  return (
    <Styles.AboutSection>
      <Section number={number} heading={heading} id={id}>
        <Styles.AboutContent>
          <Styles.AboutPortrait
            src={portraitImage}
            alt="Sam and Beth in black and white"
          />
          <Styles.AboutText>
            <p>
              <span>THE GOUDIES</span> (GOW-dees) are South Wales based, husband
              and wife folk- country duo — Sam &amp; Beth Goudie. Their close
              harmonies and honest sound have drawn comparison to the
              'California Sound' of the 60s/70s Laurel Canyon and Americana
              artists such as The Civil Wars and Gillian Welch &amp; David
              Rawlings.
            </p>
            <p>
              As singers and songwriters they explore the relationship of love,
              faith, and the spaces in between — always ending in hope.
            </p>
            <p>
              Their music has been added to the BBC Radio Wales Welsh A-List and
              received radio play on BBC Introducing and Absolute Radio Country
              - as well as featuring at the British Country Music Festival and
              Big Church Festival.
            </p>
            <p>
              With the release of their single “L.A. Never Leaves Me”, The
              Goudies mark a significant musical milestone. Their collaboration
              with producer Marc MacNab-Jack (Kill It Kid, Band of Skulls) has
              brought a rich new dimension to their heartfelt storytelling -
              presenting a sound that expands beyond their previous acoustic
              recordings.
            </p>
            {context === "epk" && (
              <>
                <h4>Background</h4>
                <p>
                  Sam and Beth met in Cardiff in 2008. Sam was playing a show in
                  a yurt under his then moniker &apos;Elephant &amp;
                  Soldier&apos;, and they started playing the same shows across
                  South Wales.
                </p>
                <p>
                  After a few years of sharing songs and stages, in 2014 they
                  married and began their life together. Whilst living in Dorset
                  and starting a family, Beth recorded and released her solo
                  album “Our Hearts Will Conquer” which also featured Sam on
                  guitar and vocals.
                </p>
                <p>
                  During the lockdown of 2020, The Goudies began to take form
                  over housebound songwriting sessions. Sam and Beth knew that
                  it was time to leave their home in Dorset, but weren&apos;t
                  yet sure where they were headed. All they knew is that need to
                  get lighter and make their world bigger again.
                </p>
                <p>
                  In 2021, they sold all of their furniture, gave back the keys
                  to their house, packed up their guitars and kids in their van,
                  and set off to find a new place called home.
                </p>
                <p>
                  That journey finally led them to the California. However, in
                  driving the backroads of Laurel Canyon and through the
                  mountains of the North, they found they couldn't stop thinking
                  about the place where they first started - the valleys and
                  vales surrounding Cardiff, Wales.
                </p>
                <p>
                  After seven years away, 2022 saw The Goudies making home and
                  reconnecting with their roots in the South Wales music scene.
                </p>
              </>
            )}
          </Styles.AboutText>
        </Styles.AboutContent>
      </Section>
    </Styles.AboutSection>
  )
}

export default AboutSection
